import { Button, CustomerViewSearchIcon } from 'src/components/designsystem'
import { useMerchandiser } from 'src/data/merchandiser'
import { useDefaultLandingFeature } from 'src/utils'
import { useState } from 'react'
import { usePathname } from 'next/navigation'
import { persist } from 'zustand/middleware'
import { create } from 'zustand'

type BackToCrmStore = {
  backToUrl: string
  setBackToUrl: (backToUrl: string) => void
  clear: () => void
}

export const useBackToCrmUrlStore = create<BackToCrmStore>()(
  persist<BackToCrmStore>(
    (set) => ({
      backToUrl: null,
      setBackToUrl: (backToUrl: string) => {
        set({ backToUrl })
      },
      clear: () => {
        set({ backToUrl: null })
      },
    }),
    {
      name: 'back-to-crm-url',
    }
  )
)

export default function CustomerViewButton({ userId }: Readonly<{ userId: number }>) {
  const { handleSelectUser } = useMerchandiser()
  const { setBackToUrl } = useBackToCrmUrlStore()
  const path = usePathname()
  const landingFeature = useDefaultLandingFeature()
  const [isLoading, setIsLoading] = useState(false)

  const viewCustomer = () => {
    setIsLoading(true)
    const user: SearchUser = {
      id: userId,
      userId: userId,
      accounts: [],
    }

    setBackToUrl(path)
    handleSelectUser(user, landingFeature)
  }

  return (
    <Button
      variant="outline"
      isLoading={isLoading}
      leftIcon={<CustomerViewSearchIcon fontSize="xl" />}
      onClick={viewCustomer}
      data-testid="customer-view-button"
    >
      Customer View
    </Button>
  )
}
