import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import { aggregatorService, HTTPError } from 'src/api'
import { customPagesQueryKeys } from './customPagesQueryKey'
import { getAggregatorAppConfigQueryOptions } from 'src/data/queries/config'

export default function useStaffSaveCustomPage(
  id?: string,
  options?: UseMutationOptions<StaffCustomPageByIdResponse, HTTPError, StaffCustomPageCreateRequest>
) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: StaffCustomPageCreateRequest) =>
      id
        ? aggregatorService.staffUpdateCustomPage(id, data)
        : aggregatorService.staffCreateCustomPage(data),
    ...options,
    onSuccess: (data, variables, context) => {
      // The sidebar needs to updated by invalidating the cache to the aggregator config.
      queryClient.invalidateQueries(getAggregatorAppConfigQueryOptions)
      queryClient.invalidateQueries({ queryKey: customPagesQueryKeys.listStaff() })
      queryClient.invalidateQueries({ queryKey: customPagesQueryKeys.list() })
      if (id) {
        queryClient.invalidateQueries({ queryKey: customPagesQueryKeys.getByIdStaff(id) })
        queryClient.invalidateQueries({ queryKey: customPagesQueryKeys.getById(id) })
      }
      options?.onSuccess(data, variables, context)
    },
    meta: {
      errorMessage: `Unable to ${id ? 'update' : 'create'} custom page, please try again.`,
    },
  })
}
