import { queryOptions, useQuery } from '@tanstack/react-query'
import { aggregatorService } from 'src/api'
import { useAuth } from 'src/auth'
import { customPagesQueryKeys } from 'src/data/queries/custom-pages'

// 'Expiring' files from the File Service use the `X-Goog-Expires` queryParam, defined in seconds, converted to milliseconds for our uses.
const customPagesStaleTime = 1000 * 60 * 2 // 2 minutes

const getCustomPageByIdOptions = (id: string) =>
  queryOptions({
    queryKey: customPagesQueryKeys.getById(id),
    queryFn: () =>
      id ? aggregatorService.getCustomPageById(id) : Promise.reject(new Error('No id provided')),
    select: (data) => data.data,
    refetchOnWindowFocus: true,
    gcTime: customPagesStaleTime,
    staleTime: customPagesStaleTime,
    refetchInterval: customPagesStaleTime,
    meta: { errorMessage: 'Unable to retrieve that page, please try again.' },
  })

type GetCustomPageByIdOptions = Partial<ReturnType<typeof getCustomPageByIdOptions>>

export default function useGetCustomPageById(id?: string, options: GetCustomPageByIdOptions = {}) {
  const { isStaff } = useAuth()
  return useQuery({
    ...getCustomPageByIdOptions(id),
    enabled: !!id && !isStaff,
    ...options,
  })
}
