import { Box, Circle, Flex, Spacer, Text } from 'src/components/designsystem'
import { ActiveIconName, asIcon, StandardIconName } from 'src/utils/icons'
import { DataColors } from 'src/themes/dataColors'
import { SidebarItemProps } from './SidebarItem'

interface ActiveOrStandardIconProps {
  isActive: boolean
  standardIcon: StandardIconName
  activeIcon: ActiveIconName
  isPinned: boolean
  badgeCount: number
  isStaffPage: boolean
}

function ActiveOrStandardIcon({
  isActive,
  standardIcon,
  activeIcon,
  isPinned,
  badgeCount,
  isStaffPage,
}: Readonly<ActiveOrStandardIconProps>) {
  const StandardIcon = asIcon(standardIcon)
  const ActiveIcon = asIcon(activeIcon)
  return (
    <Box p={2}>
      <ActiveIcon
        boxSize={6}
        textColor={isStaffPage ? 'gray.50' : 'blue.500'}
        marginX={2}
        opacity={isActive ? 100 : 0}
        position="absolute"
        transitionDuration="normal"
      />
      <StandardIcon
        boxSize={6}
        textColor={isStaffPage ? 'gray.50' : 'gray.700'}
        marginX={2}
        opacity={isActive ? 0 : 100}
        position="relative"
        transitionDuration="normal"
      />
      {!isPinned && badgeCount > 0 && (
        <Circle
          bg={DataColors.red}
          size="4"
          color="white"
          fontSize="12px"
          fontWeight="normal"
          pos="absolute"
          top={2}
          left={8}
        >
          {badgeCount}
        </Circle>
      )}
    </Box>
  )
}

export function SidebarItemButtonCell({ item }: Readonly<{ item: SidebarItemProps }>) {
  const { isActive, standardIcon, activeIcon, ariaLabel, badgeCount, isPinned, isStaffPage } = item
  const isStaffPageBackgroundColor = isStaffPage ? 'white' : 'blue.500'
  const isActiveTextColor = isActive ? 'blue.500' : 'gray.700'

  return (
    <Flex align="center" w="full">
      {/* little blue line on the left, absolute so it doesn't affect others */}
      <Box
        bgColor={isActive ? isStaffPageBackgroundColor : 'transparent'}
        height={10}
        position="absolute"
        transitionDuration="normal"
        width="3px"
      />
      <ActiveOrStandardIcon
        {...{ isActive, standardIcon, activeIcon, isPinned, badgeCount, isStaffPage }}
      />
      <Flex maxW="80%">
        <Text
          textColor={isStaffPage ? 'gray.50' : isActiveTextColor}
          fontWeight="normal"
          pr={4}
          textAlign="start"
          transitionDuration="slow"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          lineHeight="1.5rem"
        >
          {ariaLabel}
        </Text>
      </Flex>
      {isPinned && badgeCount > 0 && (
        <Flex>
          <Circle bg={DataColors.red} size="4" color="white" fontSize="12px" fontWeight="normal">
            {badgeCount}
          </Circle>
        </Flex>
      )}
      <Spacer />
    </Flex>
  )
}
