import { Box, Flex, Tooltip } from 'src/components/designsystem'
import { DataColorOption, DataColors } from 'src/themes/dataColors'

export interface MultiValueProgressBarItem {
  /** optional title, shown on hover */
  title?: string
  /** value of the bite, 0-100 */
  value: number
  /** color of bite */
  color: DataColorOption
}

export function MultiValueProgressBar({ bites }: { bites: MultiValueProgressBarItem[] }) {
  return (
    <Flex
      bg="gray.200"
      height={8}
      minWidth="200px"
      width="100%"
      borderRadius="32px"
      overflow="hidden"
      h="50px"
    >
      {bites.map(({ color, value, title }, i) => {
        return (
          <Tooltip key={color} label={title}>
            <Box
              height="100%"
              width={`${value}%`}
              backgroundColor={DataColors[color]}
              borderRight={i !== bites.length - 1 ? '2px solid white' : null}
              aria-label={title}
            />
          </Tooltip>
        )
      })}
    </Flex>
  )
}
