import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Checkbox } from '@bushelpowered/design-system'
import {
  Box,
  Button,
  ButtonGroup,
  CloseIcon,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Spinner,
  Stack,
  Text,
  useToast,
} from 'src/components/designsystem'
import { PlusIcon } from 'src/components/designsystem/icons/PlusIcon'
import { FarmField } from 'src/types/tickets/FarmField'
import { farmFieldsQueries } from 'src/data/queries/farm-fields/farm-field-queries'
import { useSetTicketFarmFields } from 'src/data/queries/tickets'
import { TrashBin } from 'src/components/designsystem/icons/TrashBin'
import { PenIcon } from 'src/components/designsystem/icons/PenIcon'
import { DeleteFarmFieldModal } from 'src/components/tickets/field-names/DeleteFarmFieldModal'
import { EditFarmFieldModal } from 'src/components/tickets/field-names/EditFarmFieldModal'
import { compareIgnoringCase } from 'src/utils/string/compare-ignoring-case'
import { ContentHeavyModal } from 'src/components/designsystem/modals/ContentHeavyModal'
import { caseInsensitiveIncludes } from 'src/utils/string/case-insensitive-includes'

export type ManageFieldNamesModalProps = {
  ticketId: number
  selectedFieldIds: number[]
  onDismiss: () => void
}

export function ManageFieldNamesModal({
  ticketId,
  selectedFieldIds: initialSelectedIds,
  onDismiss,
}: Readonly<ManageFieldNamesModalProps>) {
  // I want to experiment with this further, but don't have time right  now.
  // I've created a ticket: https://bushel.atlassian.net/browse/AGB-7438

  // State
  const [inputText, setInputText] = useState('')
  const [selectedIds, setSelectedIds] = useState<number[]>(initialSelectedIds)
  const [farmFieldToDelete, setFarmFieldToDelete] = useState<FarmField | undefined>(undefined)
  const [farmFieldToEdit, setFarmFieldToEdit] = useState<FarmField | undefined>(undefined)

  // Queries
  const farmFieldsQuery = farmFieldsQueries.useGetFarmFields()
  const createFarmFieldMutation = farmFieldsQueries.useCreateFarmField()
  const assignFieldToTicketMutation = useSetTicketFarmFields()

  // Computed constants
  const isDirty = useMemo(
    () =>
      selectedIds.length !== initialSelectedIds.length ||
      selectedIds.some((value) => initialSelectedIds.find((id) => id === value) === undefined),
    [selectedIds, initialSelectedIds]
  )
  const allFarmFields = useMemo(() => farmFieldsQuery.data ?? [], [farmFieldsQuery.data])
  const filteredFields = allFarmFields.filter((field) =>
    caseInsensitiveIncludes(field.name, inputText)
  )
  const hasInputText = inputText.trim().length > 0
  const emptyInputText = !hasInputText
  const inputTextIsDuplicate = allFarmFields.some((field) =>
    compareIgnoringCase(field.name, inputText)
  )
  const queriesAreActive =
    farmFieldsQuery.isPending ||
    createFarmFieldMutation.isPending ||
    assignFieldToTicketMutation.isPending

  // Behavior
  const toast = useToast()

  function addFieldName() {
    createFarmFieldMutation.mutate(
      { name: inputText },
      {
        onSuccess: (data) => {
          toast({
            title: 'Created field name.',
            status: 'success',
            isClosable: true,
          })
          setSelectedIds([...selectedIds, data.id])
          setInputText('')
        },
        onError: () => {
          toast({
            title: 'Failed to create field name.',
            status: 'error',
            isClosable: true,
          })
        },
      }
    )
  }

  function save() {
    const allFieldIds = allFarmFields.map((field) => field.id)
    const validSelectedIds = selectedIds.filter((id) => allFieldIds.includes(id))

    assignFieldToTicketMutation.mutate(
      {
        ticketId,
        farmFieldIds: validSelectedIds,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Assigned fields to ticket.',
            status: 'success',
            isClosable: true,
          })
          onDismiss()
        },
        onError: () => {
          toast({
            title: 'Failed to assign fields to ticket.',
            status: 'error',
            isClosable: true,
          })
        },
      }
    )
  }

  // TSX
  return (
    <>
      {!farmFieldToDelete && !farmFieldToEdit && (
        <ContentHeavyModal
          onDismiss={onDismiss}
          header={
            <>
              <Text textStyle="h5">
                {initialSelectedIds.length === 0 ? 'Add field name' : 'Manage field names'}
              </Text>
              <Box height={7} />
              <Skeleton isLoaded={!farmFieldsQuery.isLoading}>
                <Text>
                  {!farmFieldsQuery.isLoading && allFarmFields.length === 0
                    ? 'Create your first field name by typing in the form below.'
                    : 'Add a field name or select from the list of previously created field name(s) below.'}
                </Text>
              </Skeleton>
              <Box height={6} />
              <Skeleton isLoaded={!farmFieldsQuery.isLoading}>
                <Text>Field name</Text>
              </Skeleton>
              <Box height={1} />
              <Skeleton isLoaded={!farmFieldsQuery.isLoading}>
                <FieldNameInput
                  inputText={inputText}
                  setInputText={setInputText}
                  isDisabled={queriesAreActive}
                />
              </Skeleton>
              <Box height={4} />
              <Skeleton isLoaded={!farmFieldsQuery.isLoading}>
                <AddFieldNameButton
                  onClick={addFieldName}
                  isLoading={createFarmFieldMutation.isPending}
                  isDisabled={emptyInputText || inputTextIsDuplicate || queriesAreActive}
                />
              </Skeleton>
            </>
          }
          body={
            <>
              {farmFieldsQuery.isLoading && <Skeleton height={6} />}
              {!farmFieldsQuery.isLoading && allFarmFields.length > 0 && (
                <Stack>
                  <Box height={6} />
                  <FieldList
                    fields={hasInputText ? filteredFields : allFarmFields}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    onDelete={setFarmFieldToDelete}
                    onEdit={setFarmFieldToEdit}
                    isDisabled={queriesAreActive}
                  />
                </Stack>
              )}
            </>
          }
          footer={
            <ButtonGroup width={{ base: 'full', lg: 'unset' }}>
              <CancelButton onClick={onDismiss} />
              <SaveButton
                onClick={save}
                isLoading={assignFieldToTicketMutation.isPending}
                isDisabled={queriesAreActive || !isDirty}
              />
            </ButtonGroup>
          }
        />
      )}

      {farmFieldToDelete && (
        <DeleteFarmFieldModal
          farmField={farmFieldToDelete}
          onBack={() => setFarmFieldToDelete(undefined)}
          onDismiss={onDismiss}
        />
      )}

      {farmFieldToEdit && (
        <EditFarmFieldModal
          farmField={farmFieldToEdit}
          allFarmFields={allFarmFields}
          onBack={() => setFarmFieldToEdit(undefined)}
          onDismiss={onDismiss}
        />
      )}
    </>
  )
}

function FieldNameInput({
  inputText,
  setInputText,
  isDisabled,
}: Readonly<{ inputText: string; setInputText: (text: string) => void; isDisabled: boolean }>) {
  const hasText = inputText.trim().length > 0

  return (
    <InputGroup>
      <Input
        placeholder="Type or select a field name"
        value={inputText}
        onChange={(event) => setInputText(event.target.value)}
        isDisabled={isDisabled}
      />
      {hasText && (
        <InputRightElement p={2}>
          <CloseIcon onClick={() => setInputText('')} cursor="pointer" />
        </InputRightElement>
      )}
    </InputGroup>
  )
}

function AddFieldNameButton({
  onClick,
  isLoading,
  isDisabled,
}: Readonly<{
  onClick: () => void
  isLoading: boolean
  isDisabled: boolean
}>) {
  // isLoading on the button would center a spinner, but that looks bad.
  // We manually implement the loading UX to left-align the spinner.
  return (
    <Button
      onClick={onClick}
      isDisabled={isDisabled}
      leftIcon={!isLoading && <PlusIcon />}
      variant="link"
    >
      {!isLoading ? <Text>Add as field name</Text> : <Spinner size="sm" />}
    </Button>
  )
}

function FieldList({
  fields,
  selectedIds,
  setSelectedIds,
  onDelete: deleteField,
  onEdit: edit,
  isDisabled,
}: Readonly<{
  fields: FarmField[]
  selectedIds: number[]
  setSelectedIds: Dispatch<SetStateAction<number[]>>
  onDelete: (field: FarmField) => void
  onEdit: (field: FarmField) => void
  isDisabled: boolean
}>) {
  function updateSelectedIds(id: number, newState: boolean) {
    setSelectedIds((currentSelectedIds) => {
      if (newState) {
        return [...currentSelectedIds, id]
      } else {
        return currentSelectedIds.filter((selectedId) => selectedId !== id)
      }
    })
  }

  const sortedFields = useMemo(
    () =>
      fields.toSorted((a, b) => {
        const aSelected = selectedIds.includes(a.id)
        const bSelected = selectedIds.includes(b.id)

        if (aSelected && !bSelected) return -1
        if (!aSelected && bSelected) return 1

        return a.name.localeCompare(b.name)
      }),
    [fields, selectedIds]
  )

  return (
    <Stack spacing={6}>
      {sortedFields.map((field) => (
        <FarmFieldRow
          key={field.id}
          field={field}
          isSelected={selectedIds.includes(field.id)}
          onCheckmarkChanged={(newState) => updateSelectedIds(field.id, newState)}
          onDelete={() => deleteField(field)}
          onEdit={() => edit(field)}
          isDisabled={isDisabled}
        />
      ))}
    </Stack>
  )
}

function FarmFieldRow({
  field,
  isSelected,
  onCheckmarkChanged,
  onDelete,
  onEdit,
  isDisabled,
}: Readonly<{
  field: FarmField
  isSelected: boolean
  onCheckmarkChanged: (newState: boolean) => void
  onDelete: () => void
  onEdit: () => void
  isDisabled: boolean
}>) {
  return (
    <HStack spacing={6}>
      <Checkbox
        isChecked={isSelected}
        onChange={(event) => onCheckmarkChanged(event.target.checked)}
        isDisabled={isDisabled}
        width="full"
      >
        {field.name}
      </Checkbox>
      <IconButton
        icon={<TrashBin />}
        variant="unstyled"
        aria-label={`Delete ${field.name}`}
        boxSize={6}
        onClick={onDelete}
        isDisabled={isDisabled}
        data-testid={`delete-${field.id}`}
      />
      <IconButton
        icon={<PenIcon />}
        variant="unstyled"
        aria-label={`Edit ${field.name}`}
        boxSize={6}
        onClick={onEdit}
        isDisabled={isDisabled}
        data-testid={`edit-${field.id}`}
      />
    </HStack>
  )
}

function CancelButton({
  onClick,
}: Readonly<{
  onClick: () => void
}>) {
  return (
    <Button onClick={onClick} variant="secondary" width={{ base: 'full', lg: '100px' }}>
      Cancel
    </Button>
  )
}

function SaveButton({
  onClick,
  isLoading,
  isDisabled,
}: Readonly<{
  onClick: () => void
  isLoading: boolean
  isDisabled: boolean
}>) {
  return (
    <Button
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isDisabled}
      variant="primary"
      width={{ base: 'full', lg: '100px' }}
    >
      Save
    </Button>
  )
}
