import { useMemo } from 'react'
import { AlertCircleIcon, CollapsableDetailTable, Tooltip } from 'src/components/designsystem'
import DisplayConfig, { DisplayConfigItem } from 'src/components/designsystem/display-config'
import { DetailGrid, FormattedDateTime, useStackedDetailModal } from 'src/components/resource'
import { getRemarkValue } from 'src/components/tickets/helpers'
import { useConfig } from 'src/data/config'

function useDisplayConfig({ commodityName }): DisplayConfigItem<SettlementTicket>[] {
  const { tickets: ticketsConfig } = useConfig()
  const ticketListRemarks = ticketsConfig.listRemarks()
  const ignoreWeighingTimezones = ticketsConfig.ignoreWeighingTimezone()

  return useMemo(
    () => [
      {
        label: 'Ticket',
        key: 'remote_id',
        ValueCellComponent: DisplayConfig.BoldTextValue,
        getValue: () => true, // Don't want to ever em-dash this value
        renderValue: ({ data }) => {
          if (data.id) return data.remote_id

          return (
            <>
              {data.remote_id}
              <Tooltip label="Ticket unavailable" hasArrow placement="top">
                <AlertCircleIcon color="red.500" fontSize="lg" ml={1} mb={0.5} />
              </Tooltip>
            </>
          )
        },
      },
      {
        label: 'Date/Time',
        key: 'created_at',
        ValueCellComponent: DisplayConfig.BoldTextValue,
        getValue: ({ data }) => (!data.id ? null : data.created_at),
        renderValue: ({ data }) => (
          <FormattedDateTime date={data.created_at} localize={!ignoreWeighingTimezones} />
        ),
      },
      {
        label: 'Location',
        key: 'location_name',
        ValueCellComponent: DisplayConfig.BoldTextValue,
        getValue: ({ data }) => (!data.id ? null : data.location_name),
      },
      {
        label: 'Net Units',
        key: 'net_amount',
        textAlign: 'right',
        ValueCellComponent: DisplayConfig.BoldTextValue,
        getValue: ({ data }) => (!data.id ? null : data.net_amount),
        renderValue: ({ data }) => `${data.net_amount} ${data.crop_amount_uom}`,
      },
      {
        label: 'Remark',
        key: 'remarks',
        ValueCellComponent: DisplayConfig.BoldTextValue,
        renderValue: ({ data }) => {
          const primaryRemarkName =
            ticketListRemarks?.find((remark) => remark.commodity === commodityName)?.remark ??
            'Moisture'

          return getRemarkValue(primaryRemarkName, data.remarks)
        },
      },
    ],
    [commodityName, ignoreWeighingTimezones, ticketListRemarks]
  )
}

export interface AssociatedTicketsProps {
  tickets: SettlementTicket[]
  commodityName: string
}

export default function AssociatedTickets({ tickets, commodityName }: AssociatedTicketsProps) {
  const displayConfig = useDisplayConfig({ commodityName })
  const { setItem } = useStackedDetailModal()

  if (tickets.length < 1) return null

  return (
    <>
      <DetailGrid.Title>Associated Scale Tickets</DetailGrid.Title>
      <CollapsableDetailTable
        rowItems={tickets}
        rowItemTitle="Tickets"
        displayConfig={displayConfig}
        analyticsCategory="Settlements"
        analyticsAction="Associated Tickets List"
        setItem={(item) => setItem({ type: 'ticket', item })}
        shouldShowViewButton={(ticket) => !!ticket.id}
      />
    </>
  )
}
