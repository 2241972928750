import { queryOptions, useQuery } from '@tanstack/react-query'
import { aggregatorService } from 'src/api'
import { useAuth } from 'src/auth'
import { customPagesQueryKeys } from './customPagesQueryKey'

// 'Expiring' files from the File Service use the `X-Goog-Expires` queryParam, defined in seconds, converted to milliseconds for our uses.
const staffCustomPagesStaleTime = 1000 * 60 * 2 // 2 minutes

const getStaffCustomPageByIdOptions = (id: string) =>
  queryOptions({
    queryKey: customPagesQueryKeys.getByIdStaff(id),
    queryFn: () =>
      id
        ? aggregatorService.getStaffCustomPageById(id)
        : Promise.reject(new Error('No id provided')),
    select: (data) => data.data,
    refetchOnWindowFocus: true,
    gcTime: staffCustomPagesStaleTime,
    staleTime: staffCustomPagesStaleTime,
    refetchInterval: staffCustomPagesStaleTime,
    meta: { errorMessage: 'Unable to retrieve that page, please try again.' },
  })

type GetStaffCustomPageByIdOptions = Partial<ReturnType<typeof getStaffCustomPageByIdOptions>>

export default function useGetStaffCustomPageById(
  id?: string,
  options: GetStaffCustomPageByIdOptions = {}
) {
  const { isStaff } = useAuth()
  return useQuery({
    ...getStaffCustomPageByIdOptions(id),
    enabled: !!id && isStaff,
    ...options,
  })
}
