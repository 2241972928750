import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import { aggregatorService } from 'src/api'
import { customPagesQueryKeys } from './customPagesQueryKey'
import { getAggregatorAppConfigQueryOptions } from 'src/data/queries/config'

export default function useStaffDeleteCustomPage(id: string, options?: UseMutationOptions) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => aggregatorService.staffDeleteCustomPage(id),
    ...options,
    onSuccess: async (data, variables, context) => {
      // The sidebar needs to updated by invalidating the cache to the aggregator config.
      queryClient.invalidateQueries(getAggregatorAppConfigQueryOptions)
      // Clear the list before returning, creates a weird flicker otherwise
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: customPagesQueryKeys.listStaff() }),
        queryClient.invalidateQueries({ queryKey: customPagesQueryKeys.list() }),
      ])
      queryClient.removeQueries({ queryKey: customPagesQueryKeys.getByIdStaff(id) })
      queryClient.removeQueries({ queryKey: customPagesQueryKeys.getById(id) })
      options?.onSuccess(data, variables, context)
    },
    meta: {
      errorMessage: 'Please try again',
    },
  })
}
