import { AnimatePresence, motion, type Transition } from 'framer-motion'
import { useRef } from 'react'
import { useClickAway, useDebounce, usePrevious } from 'react-use'
import {
  Box,
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  SearchIcon,
  Skeleton,
  forwardRef,
  type IconButtonProps,
  type InputProps,
} from 'src/components/designsystem'

const transition: Transition = { bounce: 0, damping: 20, stiffness: 300, velocity: 20 }

// See useExpandableSearchButton()
export type ExpandableSearchButtonProps = Readonly<{
  isLoading?: boolean
  isExpanded: boolean
  setIsExpanded: (expanded: boolean) => void
  searchTerm: string
  setSearchTerm?: (searchTerm: string) => void
  onChange: (value: string) => void
  debounceDelay?: number
  placeholder: string
}>

export default function ExpandableSearchButton({
  isLoading = false,
  isExpanded,
  setIsExpanded,
  searchTerm,
  setSearchTerm,
  onChange,
  placeholder,
  debounceDelay = 300,
}: ExpandableSearchButtonProps) {
  const ref = useRef<HTMLDivElement>()
  const inputRef = useRef<HTMLInputElement>()
  useDebounce(() => onChange(searchTerm), debounceDelay, [searchTerm])
  useClickAway(ref, () => setIsExpanded(false), ['click'])

  const onClear = () => {
    inputRef.current?.focus()
    onChange('')
    setSearchTerm('')
  }

  const onClickSearch = () => {
    setIsExpanded(!isExpanded)
    if (!isExpanded) {
      requestAnimationFrame(() => inputRef?.current?.focus())
    }
  }

  const onChangeInput: React.ChangeEventHandler<HTMLInputElement> = (event) =>
    setSearchTerm(event.target.value)

  if (isLoading) {
    return <Skeleton boxSize="32px" />
  }

  return (
    <InputGroup
      ref={ref}
      as={motion.div}
      py={0.5}
      size="sm"
      overflow="hidden"
      initial={isExpanded ? 'expanded' : 'collapsed'}
      transition={transition as any}
      animate={isExpanded ? 'expanded' : 'collapsed'}
      variants={{
        collapsed: { width: '32px' },
        expanded: { width: '100%' },
      }}
    >
      <InputLeftAddon px={0} borderWidth={0} pr="1px" bg="none" zIndex={2}>
        <IconButton
          boxSize={8}
          onClick={onClickSearch}
          aria-label="Open search"
          icon={<SearchIcon fontSize="20px" />}
          rounded={isExpanded ? '4px 0 0 4px' : 4}
          variant={searchTerm.length > 0 ? 'primary' : 'secondary'}
        />
      </InputLeftAddon>
      <ExpandableSearchInput
        ref={inputRef}
        placeholder={placeholder}
        value={searchTerm}
        isExpanded={isExpanded}
        onChange={onChangeInput}
      />
      <ExpandableSearchClearButton
        onClick={onClear}
        isExpanded={isExpanded}
        searchIsEmpty={!searchTerm.length}
      />
    </InputGroup>
  )
}

type ExpandableSearchInputProps = Readonly<
  InputProps & {
    isExpanded: boolean
    placeholder: string
  }
>

const ExpandableSearchInput = forwardRef<ExpandableSearchInputProps, typeof Input>(
  ({ isExpanded, placeholder, ...props }, ref) => (
    <AnimatePresence>
      {isExpanded && (
        <Box
          as={motion.div}
          zIndex={1}
          overflow="hidden"
          exit={{ width: 0 }}
          transition={transition}
          variants={{
            collapsed: { width: '0%', opacity: 0 },
            expanded: { width: '100%', opacity: 1 },
          }}
        >
          <Input
            ref={ref}
            bgColor="white"
            rounded="0px 4px 4px 0px"
            aria-label="Search field"
            placeholder={placeholder}
            _focusWithin={
              isExpanded ? { boxShadow: 'none', borderColor: 'gray.300' } : { boxShadow: 'none' }
            }
            {...props}
          />
        </Box>
      )}
    </AnimatePresence>
  )
)

type ExpandableSearchClearButtonProps = Readonly<
  Partial<IconButtonProps> & {
    isExpanded: boolean
    searchIsEmpty: boolean
  }
>

const ExpandableSearchClearButton = forwardRef<ExpandableSearchClearButtonProps, typeof IconButton>(
  ({ isExpanded, searchIsEmpty, ...props }, ref) => {
    const prevIsExpanded = usePrevious(isExpanded)
    return (
      <AnimatePresence>
        {isExpanded && !searchIsEmpty && (
          <Box
            as={motion.div}
            zIndex={1}
            exit={{ opacity: 0 }}
            transition={transition}
            variants={{
              collapsed: { opacity: 0 },
              expanded: { opacity: 1, transition: { delay: !prevIsExpanded ? 0.33 : 0 } },
            }}
          >
            <InputRightElement pr={4} ref={ref} top="unset">
              <Button
                h={4}
                minW="5ch"
                textStyle="body"
                variant="secondary"
                aria-label="Clear search"
                {...props}
                size="xs" // Force xs to avoid Chakra's inheritance of sm from InputGroup
              >
                Clear
              </Button>
            </InputRightElement>
          </Box>
        )}
      </AnimatePresence>
    )
  }
)
