import { Card, CardBody, CardHeader, Stack, Text } from 'src/components/designsystem'
import CardSectionSkeleton from './CardSectionSkeleton'
import { SectionSubHeading } from './SectionHeading'

type ParagraphsSectionProps = Readonly<
  { isLoading: boolean } & Pick<StaffCustomPageDetails, 'paragraphs'>
>

export const sectionCardBodyPadding = { base: 4, lg: 6 }

export default function ParagraphsSection({ isLoading, paragraphs }: ParagraphsSectionProps) {
  if (isLoading) {
    return <CardSectionSkeleton height="166px" />
  }

  return (
    <Stack gap={3}>
      <SectionSubHeading>PARAGRAPHS</SectionSubHeading>
      <Stack gap={2}>
        {paragraphs?.map((paragraph, index) => (
          <Card
            key={paragraph.title ? `${paragraph.title}-${index}` : `paragraph-${index}`}
            variant="outline"
            boxShadow="none"
          >
            {paragraph.title && (
              <CardHeader px={sectionCardBodyPadding} fontWeight="bold" pb={0}>
                {paragraph.title}
              </CardHeader>
            )}
            <CardBody px={sectionCardBodyPadding}>
              <Text whiteSpace="pre-line">{paragraph.description}</Text>
            </CardBody>
          </Card>
        ))}
      </Stack>
    </Stack>
  )
}
